import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
const VARIANT_TO_ELEMENT = {
    body: 'p',
    bodyS: 'p',
    button: 'span',
    caption: 'span',
    chip: 'span',
    code: 'code',
    helperText: 'span',
    label: 'span',
    labelL: 'span',
    link: 'span',
    numberXL: 'span',
    titleL: 'h2',
    titleM: 'h3',
    titleS: 'h4',
    titleXL: 'h1',
    titleXS: 'h5',
};
const Text = ({ children, className = '', color, component = 'div', label, noWrap, onClick, style = {}, variant = 'body', href, }) => {
    const Component = component || VARIANT_TO_ELEMENT[variant];
    return (_jsx(Component, { "aria-label": label, className: clsx(`text-${variant}`, noWrap && 'text-truncate', className), href: href, onClick: onClick, style: { color, ...style }, ...(href && { target: '_blank' }), children: children }));
};
export default Text;
